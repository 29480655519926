import React, { useMemo, ReactNode, FormEvent, ChangeEvent } from 'react';

import serialize from 'form-serialize';

import flow from 'lodash/fp/flow';
import values from 'lodash/fp/values';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';
import omit from 'lodash/fp/omit';

export { Validated } from './Validated';
export { ValidatedInput } from './ValidatedInput';
export { ValidatedSelect } from './ValidatedSelect';
export { ValidatedButton } from './ValidatedButton';

interface ValidatedFormContextType {
  valid: boolean;
  setValidity: (name: string, valid: boolean) => void;
  setHideValidity: React.Dispatch<React.SetStateAction<boolean>>;
  removeField: (name: string) => void;
  hideValidity: boolean;
}

export const ValidatedFormContext = React.createContext<
  ValidatedFormContextType | undefined
>(undefined);

const isValid = flow(
  values,
  map((value: { valid: boolean }) => value.valid),
  filter((v: boolean) => v !== true),
  isEmpty,
);

interface FormState {
  [key: string]: { valid: boolean };
}

type FormAction =
  | { type: 'UPDATE'; name: string; valid: boolean }
  | { type: 'REMOVE'; name: string };

function formStateReducer(state: FormState, action: FormAction): FormState {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, [action.name]: { valid: action.valid } };
    case 'REMOVE':
      return omit(action.name)(state) as FormState;
    default:
      return state;
  }
}

interface ValidatedFormProps {
  onChange?: (formData: any) => void;
  onSubmit?: (formData: any) => void;
  onValidChange?: (valid: boolean) => void;
  children?: ReactNode;
  [key: string]: any;
}

export const ValidatedForm: React.FC<ValidatedFormProps> = ({
  onChange = () => {},
  onSubmit = () => {},
  onValidChange = () => {},
  ...props
}) => {
  const [state, dispatch] = React.useReducer(formStateReducer, {});
  const [valid, setValid] = React.useState<boolean | undefined>(undefined);

  const validatedFormRef = React.useRef<HTMLFormElement>(null);

  const setValidity = React.useCallback(
    (name: string, valid: boolean) => {
      dispatch({ type: 'UPDATE', name, valid });
    },
    [dispatch],
  );

  const [hideValidity, setHideValidity] = React.useState(true);

  const removeField = React.useCallback(
    (name: string) => {
      dispatch({ type: 'REMOVE', name });
    },
    [dispatch],
  );

  React.useEffect(() => {
    setValid(isValid(state));
  }, [state]);

  React.useEffect(() => {
    if (valid !== undefined) {
      onValidChange(valid);
    }
  }, [valid, onValidChange]);

  const handleSubmit = React.useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();

      onSubmit(serialize(event.currentTarget, { hash: true, disabled: true }));
    },
    [onSubmit],
  );

  const handleChange = React.useCallback(
    (event: ChangeEvent<HTMLFormElement>) => {
      setHideValidity(false);

      onChange(serialize(event.currentTarget, { hash: true, disabled: true }));
    },
    [setHideValidity, onChange],
  );

  const handleReset = React.useCallback(() => {
    setTimeout(() => {
      if (validatedFormRef.current) {
        [...validatedFormRef.current.querySelectorAll('input')].forEach(el => {
          const event = document.createEvent('HTMLEvents');
          event.initEvent('input', true, false);
          el.dispatchEvent(event);
          setHideValidity(true);
        });
      }
    }, 10);
  }, []);

  const value = useMemo(
    (): ValidatedFormContextType => ({
      valid: !!valid,
      setValidity,
      setHideValidity,
      removeField,
      hideValidity,
    }),
    [valid, setValidity, setHideValidity, removeField, hideValidity],
  );

  return (
    <ValidatedFormContext.Provider value={value}>
      <form
        onSubmit={handleSubmit}
        onChange={handleChange}
        onReset={handleReset}
        ref={validatedFormRef}
        {...props}
      >
        {props.children}
      </form>
    </ValidatedFormContext.Provider>
  );
};
