import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

function translateError(error, state) {
  if (typeof error === 'string') {
    switch (error) {
      case 'email is already registered to another user':
        return (
          <span>
            You may already have a CEB account using this email address. Please{' '}
            <Link to={{ pathname: '/login' }} state={state}>
              login here
            </Link>{' '}
            with your existing account to proceed, or contact CEB at
            800-232-3444.
          </span>
        );
      case 'bar number is already registered to another user':
        return (
          <span>
            You may already have a CEB account using this bar number. Please{' '}
            <Link to={{ pathname: '/login' }} state={state}>
              login here
            </Link>{' '}
            with your existing account to proceed, or contact CEB at
            800-232-3444.
          </span>
        );
      case 'email and bar number are already registered to another user':
        return (
          <span>
            You may already have a CEB account using this email address and/or
            bar number. Please{' '}
            <Link to={{ pathname: '/login' }} state={state}>
              login here
            </Link>{' '}
            with your existing account to proceed, or contact CEB at
            800-232-3444.
          </span>
        );
      case 'Firm Invitation failed due to the following errors: Invite already sent (error code: BAD_USER_INPUT)':
        return 'Invite already sent.';
      case 'Firm Invitation failed due to the following errors: Account with email already member of firm (error code: BAD_USER_INPUT)':
        return ' Account with email already member of firm.';
      default:
        return error;
    }
  }

  if (error.networkError) {
    return 'Something went wrong - please try again in a little while';
  }

  if (error.extensions && error.extensions.code === 'INVALID_CREDENTIALS') {
    return (
      <StyledInvalidCredentials>
        The CEB.com username or email and/or password you entered are incorrect.
        <span className="message-detail">
          If you are having difficulties logging in, please call Customer
          Service at 800-232-3444.
        </span>
        <span className="message-detail">
          <strong>ATTENTION ORGANIZATION ACCOUNTS:</strong> For ease of use and
          better security, every user on a organization account must have a
          personal login. If you have previously logged in to your
          organization's CEB account with an anonymous organization login,
          please create a personal login to access your organization's CEB
          subscriptions.
        </span>
      </StyledInvalidCredentials>
    );
  }

  if (
    error.extensions?.serviceResponse?.message ===
    'This user is inactive so we were unable to set the password'
  ) {
    return 'This user is inactive and needs to be updated by technical support. Please contact us for assistance.';
  }

  if (error.extensions && error.extensions.code === 'EXTERNAL_SERVICE_ERROR') {
    return 'Something went wrong. Please try again and if the problem persists contact technical support for assistance.';
  }

  if (
    error.extensions?.serviceResponse?.errors?.includes(
      'email is not associated with a user',
    )
  ) {
    return 'This email is not associated with a user. Please contact technical support for assistance.';
  }

  return error.message;
}

const StyledInvalidCredentials = styled.span`
  &,
  span {
    display: block;
  }
  span.message-detail {
    margin-top: 8px;
    font-weight: normal;
    line-height: 24px;
  }
`;

function mergeErrors(errors) {
  if (!Array.isArray(errors)) {
    // If errors is not an array, convert it to an array of error messages
    if (errors instanceof Error) {
      return [errors.message];
    } else if (typeof errors === 'object' && errors !== null) {
      return Object.values(errors).flat();
    } else {
      return [String(errors)];
    }
  }

  if (
    errors?.includes('bar number is already registered to another user') &&
    errors?.includes('email is already registered to another user')
  ) {
    return [
      'email and bar number are already registered to another user',
    ].concat(
      errors.filter(
        err =>
          ![
            'bar number is already registered to another user',
            'email is already registered to another user',
          ].includes(err),
      ),
    );
  }
  return errors;
}

function translateErrors(errors, state) {
  const errorArray = mergeErrors(errors || []);
  return errorArray.map(error => translateError(error, state));
}

export function FormErrorsList({ errors }) {
  const location = useLocation();
  const errorMessages = translateErrors(errors, location.state);

  if (errorMessages.length === 0) {
    return null;
  }

  return (
    <StyledFormErrorsList>
      {errorMessages.map((message, i) => (
        <StyledFormErrorsListItem key={i}>{message}</StyledFormErrorsListItem>
      ))}
    </StyledFormErrorsList>
  );
}

export const StyledFormErrorsList = styled.ul`
  list-style: none;
`;

export const StyledFormErrorsListItem = styled.li`
  color: ${CEB_COLOR('TUSCANY')};
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 18px;
`;
